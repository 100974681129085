import React from 'react'
import Showdown from 'showdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/pro-regular-svg-icons'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBBtnGroup } from 'mdbreact'

export default class Requirements extends React.Component {
  myRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      btnActive: this.props.btnId 
      // isExpanded: this.props.data[0].expanded ? this.props.data[0].expanded : false
    }
    this.toggleExpand = this.toggleExpand.bind(this)
  }

  componentDidMount() {
    this.setState({
      btnActive: this.props.btnId, 
      isExpanded: this.myRef && this.myRef.current.offsetHeight < 415
    })
    // console.log(this.myRef)
    this.handleClick(this.props.btnId)
  }

  handleClick(value) {
    this.setState({ btnActive: value })
  }

  toggleExpand() {
    this.setState(prevState => ({ isExpanded: !prevState.isExpanded }));
  }

  render() {
    const converter = new Showdown.Converter()
    return (
      <section className={`${this.props.bgIndex % 2 === 0 ? 'bg-light-blue-prods' : 'bg-white'}`}>
        <MDBContainer className="pb-5">
          <MDBRow>
            <MDBCol className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-4" sm="12">System requirements</MDBCol>
            {this.props.data.length > 1 && 
              <MDBCol className="text-center text-md-left">
                <MDBBtnGroup>
                  {this.props.data.map((buttons, index) => (
                    <MDBBtn 
                      className={`${this.state.btnActive === buttons.subtitle  ? 'btn-mdb-color' : 'btn-products'}`} 
                      value={buttons.subtitle}
                      onClick={() => this.handleClick(buttons.subtitle)}
                      key={index}
                    >
                      {buttons.subtitle}
                    </MDBBtn>
                  ))}
                </MDBBtnGroup>
              </MDBCol>
            }
          </MDBRow>
        </MDBContainer>

        {this.props.data.map((requirements, index) => (
          <MDBContainer 
            key={index}
            id={requirements.subtitle.toLowerCase().replace(/ /g, '-')} 
            style={{ display: this.state.btnActive === requirements.subtitle ? 'block' : 'none', maxHeight: this.state.isExpanded ? 'none' : '350px', overflow: 'hidden', }} 
          >
            {this.props.data.length > 1 && 
              <h3 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-medium pb-3">{requirements.title}</h3>
            }
            <div
              className="text-medium text-gray-dark divlink requirements"
              dangerouslySetInnerHTML={{ __html: converter.makeHtml(requirements.description) }}
              ref={index === 0 ? this.myRef : null} 
            />
          </MDBContainer>
        ))}
                    
        {!this.state.isExpanded && (
          <MDBContainer className="text-center">
            <MDBBtn className="btn btn-mdb-color" onClick={this.toggleExpand}>
              <FontAwesomeIcon icon={faArrowDown} className="mr-2" />
                Read More
                <FontAwesomeIcon icon={faArrowDown} className="ml-2" />  
              </MDBBtn>
          </MDBContainer>
        )}
      </section>
    )
  }
}