import React from 'react'
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBContainer, MDBTestimonial } from 'mdbreact'


class CarouselQuote extends React.Component {
  render() {
    const size = this.props.data.length == 1 ? ((this.props.data[0].strapline.length / 40) * 85) : 550
    const Tag = this.props.maintag ? this.props.maintag : 'section'
    return (
    <Tag className="blockback">
      <MDBContainer>
        <MDBCarousel
          activeItem={1}
          length={this.props.data.length}
          showControls={false}
          showIndicators={(this.props.data.length > 1)}
          interval={9000}
          className="carousel-height"
          style={{ minHeight: `${size}px`, maxHeight: '650px' }}
        >
          <MDBCarouselInner>
            {this.props.data.map((quote, index) => (
            <MDBCarouselItem itemId={index + 1} key={index}>
              <MDBTestimonial>
                <div className="blockquote-wrapper">
                  <div className={`${quote.author.toLowerCase().indexOf("gartner") !== -1 ? 'gartner blockquote': 'blockquote'}`}>
                    <div className="strapline">{quote.strapline}</div>
                    <div className="author">{quote.author}</div>
                  </div>
                </div>
              </MDBTestimonial>
            </MDBCarouselItem>
            ))}
          </MDBCarouselInner>
        </MDBCarousel>
      </MDBContainer>
    </Tag>
    )
  }
}

export default CarouselQuote